<template>
    <div>
        App下载页面
        <base-open-app v-if="phoneType === 'ios'"></base-open-app>
        <base-open-app v-if="phoneType === 'android'"></base-open-app>
    </div>
</template>

<script>
export default {
    computed: {
        phoneType () {
            if (this.$common.phoneType().isiOS) return 'ios'
            if (this.$common.phoneType().isAndroid) return 'android'
            return ''
        }
    },
    methods: {
        downLoad() {
            if (this.$common.phoneType().isiOS) {
                window.location.href = "https://apps.apple.com/cn/app/id1484041430";
            }
            if (this.$common.phoneType().isAndroid) {
                window.location.href = "https://3g.lenovomm.com/redsea/com.mediwelcome.hospital?rank_code=search_2_%E9%BA%A6%E8%BF%AA%E5%8C%BB%E5%8A%A0&num=1";
            }
        }
    }
}
</script>